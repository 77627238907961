<template>
  <!-- 輪播資訊 -->
  <div
    id="salesCarousel"
    class="carousel"
    data-ride="carousel"
  >
    <!-- <ol class="carousel-indicators">
                    <li v-for="(img, index) in 2" data-target="#salesCarousel" :data-slide-to="index" :class="{active:index==0}"></li>
                </ol> -->
    <div class="carousel-inner overflow-hidden">
      <div class="carousel-item active">
        <!-- <img
          class="img-fluid index-banner d-none d-md-block"
          src="@/assets/img/about_banner.png"
        >
        <img
          class="img-fluid index-banner d-block d-md-none"
          src="@/assets/img/about_banner_mobile.png"
        > -->
        <div class="position-absolute container-md position-middle text-white">
          <div class="row">
            <div class="col-12 col-md-7 col-lg-8 px-5">
              <div class="sub-title mb-4 pb-1">
                World class Team
              </div>
              <h1 class="fw-bold text-xxl">
                ABOUT
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <a class="carousel-control-prev"
                   style="width:5%"
                   href="#salesCarousel"
                   role="button"
                   data-slide="prev">
                    <span class="carousel-control-prev-icon"
                          aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next"
                   style="width:5%"
                   href="#salesCarousel"
                   role="button"
                   data-slide="next">
                    <span class="carousel-control-next-icon"
                          aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a> -->
  </div>
  <!-- 關於我們 -->
  <div class="container-fluid pt-5">
    <div class="row position-relative">
      <div class="col-12 position-relative p-0">
        <div
          class="position-absolute img-1"
          :class="{'show_block_1': showMore1}"
        >
          <!-- <img
            class="img-fluid"
            src="@/assets/img/about_pic_1.png"
          > -->
        </div>
        <div class="flex-grow-1 bg-white">
          <div class="col-12 col-md-4 offset-md-6 my-5 mb-md-0 block-1-light">
            <p class="d-none d-md-block white-space-pre-line">
              面對疫情，人心惶惶。我們希望在這紊亂的時刻，你能享有一個專屬自己、溫馨美好、洗滌你疲憊身心的暫棲之所。

              嚴格的人數控管，將百坪會所劃分成2~3個獨立運動區域，讓每組客人平均享有10~20坪的健身空間，擁有優良的運動環境與上課品質。

              這是我們取名LIGHT WORKER的由來—真誠、關懷、同理。在旖旎的陽光下，放下對心中種種不安，感受身體自由的律動；在教練的陪伴下，傾聽你的需求，打造專屬各種年齡的教練課程。
            </p>
            <div class="d-block d-md-none">
              <p class="white-space-pre-line">
                面對疫情，人心惶惶。我們希望在這紊亂的時刻，你能享有一個專屬自己、溫馨美好、洗滌你疲憊身心的暫棲之所。
              </p>
              <p
                v-show="showMore1"
                class="white-space-pre-line"
              >
                嚴格的人數控管，將百坪會所劃分成2~3個獨立運動區域，讓每組客人平均享有10~20坪的健身空間，擁有優良的運動環境與上課品質。

                這是我們取名LIGHT WORKER的由來—真誠、關懷、同理。在旖旎的陽光下，放下對心中種種不安，感受身體自由的律動；在教練的陪伴下，傾聽你的需求，打造專屬各種年齡的教練課程。
              </p>
              <div
                v-show="!showMore1"
                class="text-primary read-more d-flex align-items-center"
                role="button"
                @click="setShow(1, true)"
              >
                閱讀更多
                <div class="arrow" />
              </div>
            </div>
          </div>
        </div>
        <div class="bg-dark text-white">
          <div class="col-12 col-md-4 offset-md-6 block-1-dark">
            <h2>
              為你打造獨一無二的<br>
              專屬運動空間
            </h2>
            <p class="d-none d-md-block white-space-pre-line">
              創立LIGHT WORKER時，我們想表達的是健身並非只有正能量，也不僅止於「成為更好的自己」，健身的滋味千迴萬轉，如同人生百味。也歡迎你在人生的各種階段來造訪—我們想成為這麼一座恆久穩定的基石。
            </p>
            <div class="d-block d-md-none">
              <p :class="{'block': !showMore2}">
                創立LIGHT WORKER時，我們想表達的是健身並非只有正能量，也不僅止於「成為更好的自己」，健身的滋味千迴萬轉，如同人生百味。也歡迎你在人生的各種階段來造訪—我們想成為這麼一座恆久穩定的基石。
              </p>
              <div v-show="!showMore2">
                <div
                  class="text-primary read-more d-flex align-items-center"
                  role="button"
                  @click="setShow(2, true)"
                >
                  閱讀更多
                  <div class="arrow" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 我們成功的秘訣 -->
  <div class="container-fluid py-5 bg-grey-light">
    <div class="row py-5 mb-5">
      <div class="col-11 row align-items-center justify-content-center mx-auto">
        <div class="col-12 col-md-6 mb-3">
          <h2 class="text-primary mb-3">
            我們成功的秘訣
          </h2>
          <div class="d-block d-md-none">
            <p
              :class="{'block': !showMore3}"
              class="white-space-pre-line"
            >
              疫情紛擾，面對未知的恐懼，使人們不知何去何從。因此，我們致力於提供一個最純粹、最獨特、最舒適的私人運動空間，首創全台獨一無二的「運動包廂制」，打造全台灣「人最少」、「心最安」的私人運動招待所。

              科學證實，老化是可逆的，重訓可有效提升免疫力—健身，不再僅止於雕塑體態或瘦身，更大的原因，為了健康與尊嚴：有效維持老年的生活品質，擺脫肌少症、骨質疏鬆的各種老化困擾。
            </p>
            <div v-show="!showMore3">
              <div
                class="text-primary read-more d-flex align-items-center"
                role="button"
                @click="setShow(3, true)"
              >
                閱讀更多
                <div class="arrow" />
              </div>
            </div>
          </div>
          <!-- <p class="white-space-pre-line block"></p> -->
        </div>
        <div class="col-12 col-md-6">
          <!-- <img
            class="img-fluid"
            src="@/assets/img/about_pic_2.png"
          > -->
        </div>
      </div>
    </div>
    <!-- features -->
    <!-- pc -->
    <div class="row d-none d-md-flex col-11 mx-auto mb-5 p-0">
      <div class="col bg-white p-5 mx-1 mx-lg-4 d-flex flex-column justify-content-between feature-box">
        <div>
          <h4 class="mb-3">
            有效率的運動
          </h4>
          <p class="mb-3 white-space-pre-line">
            運用「超級組(Super Set)」上下半身交替訓練，動作之間簡短休息，時間短、效率高，能有效加強肌肉力量、燃脂效果、心肺功能，並提高新陳代謝，增強免疫力，對抗病毒。
          </p>
        </div>
        <div class="line" />
      </div>
      <div class="col bg-white p-5 mx-1 mx-lg-4 d-flex flex-column justify-content-between feature-box">
        <div>
          <h4 class="mb-3">
            零運動傷害
          </h4>
          <p class="mb-3 white-space-pre-line">
            我們訴求「先求健康，再求表現；先求安全，再求重量」—以運動科學為據，參酌教練豐富的經驗，設計出專屬於你的進退階訓練課表。不過量、不強求、安全有效。
          </p>
        </div>
        <div class="line" />
      </div>
      <div class="col bg-white p-5 mx-1 mx-lg-4 d-flex flex-column justify-content-between feature-box">
        <div>
          <h4 class="mb-3">
            零壓力的精神運動支持
          </h4>
          <p class="mb-3 white-space-pre-line">
            我們保證零運動傷害，有效地達成你的運動目標！不用擔心是否有運動經驗，或是曾有舊疾、舊傷，放心交給LIGHT WORKER的專業私人教練，絕對能幫你突破各種運動上的心理障礙與困難！
          </p>
        </div>
        <div class="line" />
      </div>
    </div>
    <!-- mobile -->
    <div class="row d-flex d-md-none col-12 mx-auto mb-5 p-0">
      <div class="col-12 d-block d-md-none p-0">
        <div
          id="featureCarousel"
          class="carousel featureCarousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner col-11 mx-auto overflow-hidden">
            <div class="carousel-item active">
              <div class="col-12 bg-white p-5 feature-box">
                <h4 class="mb-3">
                  有效率的運動
                </h4>
                <p class="mb-3 white-space-pre-line">
                  運用「超級組(Super Set)」上下半身交替訓練，動作之間簡短休息，時間短、效率高，能有效加強肌肉力量、燃脂效果、心肺功能，並提高新陳代謝，增強免疫力，對抗病毒。
                </p>
                <div class="line" />
              </div>
            </div>
            <div class="carousel-item">
              <div class="col-12 bg-white p-5 feature-box">
                <h4 class="mb-3">
                  零運動傷害
                </h4>
                <p class="mb-3 white-space-pre-line">
                  我們訴求「先求健康，再求表現；先求安全，再求重量」—以運動科學為據，參酌教練豐富的經驗，設計出專屬於你的進退階訓練課表。不過量、不強求、安全有效。
                </p>
                <div class="line" />
              </div>
            </div>
            <div class="carousel-item">
              <div class="col-12 bg-white p-5 feature-box">
                <h4 class="mb-3">
                  零壓力的精神運動支持
                </h4>
                <p class="mb-3 white-space-pre-line">
                  我們保證零運動傷害，有效地達成你的運動目標！不用擔心是否有運動經驗，或是曾有舊疾、舊傷，放心交給LIGHT WORKER的專業私人教練，絕對能幫你突破各種運動上的心理障礙與困難！
                </p>
                <div class="line" />
              </div>
            </div>
          </div>
          <a
            class="carousel-control-prev"
            style="width: 5%;"
            href="#featureCarousel"
            role="button"
            data-slide="prev"
          >
            <span class="sr-only">Previous</span>
            <div class="carousel-control-prev-icon arrow" />
          </a>
          <a
            class="carousel-control-next"
            style="width: 5%;"
            href="#featureCarousel"
            role="button"
            data-slide="next"
          >
            <span class="sr-only">Next</span>
            <div class="carousel-control-next-icon arrow" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  name: 'About',
  components: {

  },
  setup () {
    const showMore1 = ref(false)
    const showMore2 = ref(false)
    const showMore3 = ref(false)

    const setShow = (number, show) => {
      [`showMore${number}`].value = show
    }

    return {
      showMore1, showMore2, showMore3, setShow
    }
  }
}
</script>
<style lang="scss" module>

</style>
